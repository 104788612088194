@import '../assets/_var';
@import '../assets/_mixins';
@import '../assets/extends';

.kv {
	position: relative;
	z-index: 1;
	.txt-wrap {
		@extend .centering;
		z-index: 2;
		width: 100%;
		background-color: rgba(204, 0, 0, 0.75);
		color: #fff;
	}
	.txt {
		text-align: center;
		p {
			font-size: 1.9rem;
			line-height: 1.6;
			padding: 2rem 0;
		}
	}
}

/* トップ お知らせ */
.t-news {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	&::before,&::after {
		content: "";
		display: block;
		position: absolute;
		z-index: -1;
		width: 50%;
		height: 100%;
	}
	&::before {
		background-color: $black;
	}
	&::after {
		background-color: $red;
		right: 0;
		top: 0;
	}
}
.t-news-wrap {
	display: table;
	padding: 0;
}
.t-news-title {
	vertical-align: middle;
	color: #fff;
	background: $black;
	width: 25%;
	padding: 1rem 0;
	display: table-cell;
	.txt {
		margin: 0;
		font-size: 2.5rem;
		line-height: 1.6;
		font-weight: normal;
		&:after {
			content: "お知らせ";
			font-size: 1rem;
			position: relative;
			top: -6px;
		}
	}
}
.t-news-txt {
	vertical-align: middle;
	padding: 2.8rem;
	width: 75%;
	background-color: $red;
	display: table-cell;
	.txt {
		margin: 0;
		a {
			color: #fff;
			font-size: 1rem;
			font-weight: normal;
			time {
				color: rgba(255, 255, 255, 0.6);
				margin-right: .75rem;
			}
		}
	}
	.box {
		margin-bottom: .5rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

/* service */
.t-service {
	text-align: center;
	margin: 3rem auto;
	.title {
		font-size: 2rem;
		font-weight: normal;
		display: inline;
		&::after {
			content: "サービス案内";
			display: inline;
			vertical-align: middle;
			font-size: 1rem;
			color: $red;
			margin-left: 1.2rem;
			position: relative;
			top: -2px;
		}
	}
	.txt {
		margin-top: 1rem;
	}

}
.service-wrap {
	margin: 3rem auto;
	position: relative;
	&:nth-child(1) {
		a {
			&::before {
				content: "01";
			}
		}
		.box {
			.right {
				h3 {
					&::after {
						content: "General Vehicle Repair";
					}
				}
			}
		}
	}
	&:nth-child(2) {
		a {
			&::before {
				content: "02";
			}
		}
		.box {
			.right {
				h3 {
					// font-weight: normal;
					&::after {
						content: "General Vehicle Repair";
					}
				}
			}
		}
	}
	&:nth-child(3) {
		a {
			&::before {
				content: "03";
			}
		}
		.box {
			.right {
				h3 {
					&::after {
						content: "Bodywork & Paint・Repairing Damaged Vehicle";
					}
				}
			}
		}
	}
	&:nth-child(4) {
		a {
			&::before {
				content: "04";
			}
		}
		.box {
			.right {
				h3 {
					&::after {
						content: "Vehicle Insurance / Roadside Assitance";
					}
				}
			}
		}
	}
	&:nth-child(5) {
		a {
			&::before {
				content: "05";
			}
		}
		.box {
			.right {
				h3 {
					&::after {
						content: "Car Rentals";
					}
				}
			}
		}
	}
	&:nth-child(6) {
		a {
			&::before {
				content: "06";
			}
		}
		.box {
			.right {
				h3 {
					&::after {
						content: "Car Sales / Buying";
					}
				}
			}
		}
	}
	&:nth-child(2n) {
		a {
			&::after {
				background: url(../../images/parts/arrow-right-w.png) no-repeat;
			}
		}
		.box {
			background-color: $red;
			.right {
				h3 {
					color: #fff;
					&::after {
						color: #fff;
					}
				}
			}
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
	a {
		box-shadow: 3px 3px 8px 0px rgba(51, 51, 51, 0.4);
		position: relative;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			background-color: $red;
			color: #fff;
			width: 60px;
			height: 60px;
			line-height: 2;
			font-size: 2rem;
			letter-spacing: 1px;
			padding-left: .25rem;
		}
		&::after {
			content: "";
			width: 18px;
			height: 24px;
			background: url(../../images/parts/arrow-right.png) no-repeat;
			background-size: cover;
			position: absolute;
			top: 50%;
			right: 1.5rem;
			transform: translateY(-50%);
		}
		&:hover {
			@keyframes bounceW {
				0% {
					right: 1.5rem;
				}

				50% {
					right: 2.5rem;
				}

				100% {
					right: 1.5rem;
				}
			}
			&::after {
				animation: bounceW 1s ease-in-out 0s infinite;
			}
			.box {
				.left {
					.inner {
						transform: scale(1.5);
					}
				}
			}
		}
		.box {
			display: flex;
			align-items: center;
			.left {
				width: 700px;
				height: 200px;
				position: relative;
				overflow: hidden;
				z-index: 1;
				.inner {
					height: 100%;
					background-position: center !important;
					background-size: cover !important;
					transition: all 6s;
				}
			}
			.right {
				width: 580px;
				h3 {
					text-align: left;
					font-size: 1.5rem;
					padding: 0 3rem;
					font-weight: normal;
					&::after {
						content: "Lotus Smile Vehicle Inspection / Smile Vehicle Inspection ";
						font-size: .75rem;
						color: $gray;
						letter-spacing: 1px;
					}
				}
			}
		}
	}
}

/*
	==========================================================
		smart slider style
	==========================================================
*/
.n2-style-2a0a200d6a6e1fade27fb9c90af83812-dot {
	background: #fff !important;
}
.n2-active {
	background: $red !important;
}
div#n2-ss-2 .nextend-arrow {
	cursor: pointer;
	overflow: hidden;
	line-height: 0 !important;
	z-index: 20;
}


@include mq() {
	.kv {
		// height: 30rem;
		.txt-wrap {
			width: 90%;
			.txt {
				p {
					font-size: 5vw;
					line-height: 1.8;
					padding: .5rem;
				}
			}
		}
	}

	.t-news-wrap {
		display: block;
	}
	.t-news-title {
		display: block;
		width: 100%;
		text-align: center;
		.txt {
			font-size: 2rem;
		}
	}
	.t-news-txt {
		width: 100%;
		display: block;
		padding: 1rem;
		.txt {
			a {
				font-size: 1rem;
				letter-spacing: 1px;
			}
		}
	}
	.t-service {
		margin: 1rem auto;
		.txt {
			margin-top: 1.75rem;
		}
	}
	.service-wrap {
		a {
			.box {
				display: block;
				.left {
					width: 100%;
				}
				.right {
					width: 100%;
					h3 {
						margin: 0;
						padding: 1rem;
						font-size: 1.2rem;
					}
				}
			}
		}
	}

}