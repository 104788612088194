@charset "UTF-8";
.centering, .centering_fix, .kv .txt-wrap {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }
  .centeringY {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .centeringX {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .centering_fix {
    position: fixed; }

.centering_fix {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.reverse {
  display: flex;
  flex-direction: row-reverse; }

.kv {
  position: relative;
  z-index: 1; }
  .kv .txt-wrap {
    z-index: 2;
    width: 100%;
    background-color: rgba(204, 0, 0, 0.75);
    color: #fff; }
  .kv .txt {
    text-align: center; }
    .kv .txt p {
      font-size: 1.9rem;
      line-height: 1.6;
      padding: 2rem 0; }

/* トップ お知らせ */
.t-news {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center; }
  .t-news::before, .t-news::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 50%;
    height: 100%; }
  .t-news::before {
    background-color: #141414; }
  .t-news::after {
    background-color: #CC0000;
    right: 0;
    top: 0; }

.t-news-wrap {
  display: table;
  padding: 0; }

.t-news-title {
  vertical-align: middle;
  color: #fff;
  background: #141414;
  width: 25%;
  padding: 1rem 0;
  display: table-cell; }
  .t-news-title .txt {
    margin: 0;
    font-size: 2.5rem;
    line-height: 1.6;
    font-weight: normal; }
    .t-news-title .txt:after {
      content: "お知らせ";
      font-size: 1rem;
      position: relative;
      top: -6px; }

.t-news-txt {
  vertical-align: middle;
  padding: 2.8rem;
  width: 75%;
  background-color: #CC0000;
  display: table-cell; }
  .t-news-txt .txt {
    margin: 0; }
    .t-news-txt .txt a {
      color: #fff;
      font-size: 1rem;
      font-weight: normal; }
      .t-news-txt .txt a time {
        color: rgba(255, 255, 255, 0.6);
        margin-right: .75rem; }
  .t-news-txt .box {
    margin-bottom: .5rem; }
    .t-news-txt .box:last-child {
      margin-bottom: 0; }

/* service */
.t-service {
  text-align: center;
  margin: 3rem auto; }
  .t-service .title {
    font-size: 2rem;
    font-weight: normal;
    display: inline; }
    .t-service .title::after {
      content: "サービス案内";
      display: inline;
      vertical-align: middle;
      font-size: 1rem;
      color: #CC0000;
      margin-left: 1.2rem;
      position: relative;
      top: -2px; }
  .t-service .txt {
    margin-top: 1rem; }

.service-wrap {
  margin: 3rem auto;
  position: relative; }
  .service-wrap:nth-child(1) a::before {
    content: "01"; }
  .service-wrap:nth-child(1) .box .right h3::after {
    content: "General Vehicle Repair"; }
  .service-wrap:nth-child(2) a::before {
    content: "02"; }
  .service-wrap:nth-child(2) .box .right h3::after {
    content: "General Vehicle Repair"; }
  .service-wrap:nth-child(3) a::before {
    content: "03"; }
  .service-wrap:nth-child(3) .box .right h3::after {
    content: "Bodywork & Paint・Repairing Damaged Vehicle"; }
  .service-wrap:nth-child(4) a::before {
    content: "04"; }
  .service-wrap:nth-child(4) .box .right h3::after {
    content: "Vehicle Insurance / Roadside Assitance"; }
  .service-wrap:nth-child(5) a::before {
    content: "05"; }
  .service-wrap:nth-child(5) .box .right h3::after {
    content: "Car Rentals"; }
  .service-wrap:nth-child(6) a::before {
    content: "06"; }
  .service-wrap:nth-child(6) .box .right h3::after {
    content: "Car Sales / Buying"; }
  .service-wrap:nth-child(2n) a::after {
    background: url(../../images/parts/arrow-right-w.png) no-repeat; }
  .service-wrap:nth-child(2n) .box {
    background-color: #CC0000; }
    .service-wrap:nth-child(2n) .box .right h3 {
      color: #fff; }
      .service-wrap:nth-child(2n) .box .right h3::after {
        color: #fff; }
  .service-wrap:last-child {
    margin-bottom: 0; }
  .service-wrap a {
    box-shadow: 3px 3px 8px 0px rgba(51, 51, 51, 0.4);
    position: relative; }
    .service-wrap a::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: #CC0000;
      color: #fff;
      width: 60px;
      height: 60px;
      line-height: 2;
      font-size: 2rem;
      letter-spacing: 1px;
      padding-left: .25rem; }
    .service-wrap a::after {
      content: "";
      width: 18px;
      height: 24px;
      background: url(../../images/parts/arrow-right.png) no-repeat;
      background-size: cover;
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%); }

@keyframes bounceW {
  0% {
    right: 1.5rem; }
  50% {
    right: 2.5rem; }
  100% {
    right: 1.5rem; } }
    .service-wrap a:hover::after {
      animation: bounceW 1s ease-in-out 0s infinite; }
    .service-wrap a:hover .box .left .inner {
      transform: scale(1.5); }
    .service-wrap a .box {
      display: flex;
      align-items: center; }
      .service-wrap a .box .left {
        width: 700px;
        height: 200px;
        position: relative;
        overflow: hidden;
        z-index: 1; }
        .service-wrap a .box .left .inner {
          height: 100%;
          background-position: center !important;
          background-size: cover !important;
          transition: all 6s; }
      .service-wrap a .box .right {
        width: 580px; }
        .service-wrap a .box .right h3 {
          text-align: left;
          font-size: 1.5rem;
          padding: 0 3rem;
          font-weight: normal; }
          .service-wrap a .box .right h3::after {
            content: "Lotus Smile Vehicle Inspection / Smile Vehicle Inspection ";
            font-size: .75rem;
            color: #afafaf;
            letter-spacing: 1px; }

/*
	==========================================================
		smart slider style
	==========================================================
*/
.n2-style-2a0a200d6a6e1fade27fb9c90af83812-dot {
  background: #fff !important; }

.n2-active {
  background: #CC0000 !important; }

div#n2-ss-2 .nextend-arrow {
  cursor: pointer;
  overflow: hidden;
  line-height: 0 !important;
  z-index: 20; }

@media screen and (max-width: 959px) {
  .kv .txt-wrap {
    width: 90%; }
    .kv .txt-wrap .txt p {
      font-size: 5vw;
      line-height: 1.8;
      padding: .5rem; }
  .t-news-wrap {
    display: block; }
  .t-news-title {
    display: block;
    width: 100%;
    text-align: center; }
    .t-news-title .txt {
      font-size: 2rem; }
  .t-news-txt {
    width: 100%;
    display: block;
    padding: 1rem; }
    .t-news-txt .txt a {
      font-size: 1rem;
      letter-spacing: 1px; }
  .t-service {
    margin: 1rem auto; }
    .t-service .txt {
      margin-top: 1.75rem; }
  .service-wrap a .box {
    display: block; }
    .service-wrap a .box .left {
      width: 100%; }
    .service-wrap a .box .right {
      width: 100%; }
      .service-wrap a .box .right h3 {
        margin: 0;
        padding: 1rem;
        font-size: 1.2rem; } }
